/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}

*:focus,
*,
*:active,
*:hover {
  outline: none !important;
}

.arrowRight {
  top: 13px !important;
}

.text-theme {
  color: #f8c933;
}

.bg-theme {
  background: #f8c933;
}

.card-img.text-center > span {
  border-radius: 10px !important;
}

.dropdown-menu {
  right: 0;
}
.app-image {
  & span {
    display: block !important;
    top: 7px !important;
  }
}
// .dropdown-menu a:hover {
// 	background-color: rgba(0, 0, 0, 0.8);
// 	color: #f8c933;
// }
.dropdown-submenu {
  right: -256px;
  top: 0;
}

.slider-section {
  & .card {
    min-height: 360px;
  }
}

.liveLocation span {
  vertical-align: middle;
}

.img-overly {
  & span {
    display: block !important;
  }
}
.img-overly:after,
.imgOverly {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.imgOverly {
  background: transparent;
}

.bg-light {
  background: #fcfcfc;
}

.card-item span {
  display: block !important;
}
.image-default {
  & span {
    vertical-align: bottom;
  }
}
.business-slider {
  background-repeat: no-repeat;
  padding: 60px 30px;
  position: relative;
  background-size: cover;
  border-radius: 10px;
  &::after {
    content: "";
    background: rgb(0 0 0 / 50%);
    position: absolute;
    inset: 0;
    border-radius: 10px;
  }
}
.bits-container {
  & .swiper-pagination-bullet-active.swiper-pagination-bullet {
    background: #f9c933;
  }
  .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.5);
  }
  .swiper-horizontal > .swiper-pagination-bullets,
  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .swiper-pagination-custom,
  .swiper-pagination-fraction {
    bottom: 15px !important;
  }
}
.footer {
  & h4 {
    display: flex;
    align-items: center;
    border-color: #f8c933;
    padding-bottom: 5px;
    justify-content: space-between;
  }
}

.bg-yellowlight {
  background: #fff9e4;
}
.bg-themeColor {
  background-color: #f9c933;
}
.text-themeColor {
  color: #f9c933;
}
.border-themeColor {
  border-color: #f9c933;
}
select:not(.footer select) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.tabs .active {
  margin-bottom: -1px;
}
.sidebar-nav span {
  vertical-align: middle;
}
.sidebar-nav .active {
  background: #f9ca32;
}
.maxh-400 {
  max-height: 400px;
}
.table-language::-webkit-scrollbar {
  width: 2px;
}
.card-img {
  & span {
    display: block !important;
  }
}
.courser-list {
  & span {
    display: block !important;
  }
  & .bg-overly {
    background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.7));
  }
  & img {
    object-fit: cover;
  }
}
.bgOverly {
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.9));
}
.slick-prev:before,
.slick-next:before {
  font-size: 40px !important;
}
.slick-slide {
  & .story-img {
    height: 350px;
    width: 100% !important;
    & span {
      display: block !important;
    }
    & img {
      height: 350px !important;
      width: 100% !important;
      object-fit: cover !important;
      border-radius: 10px;
    }
  }
}
.slick-center.slick-slide {
  & > div {
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    -webkit-transform: scale(1.05);
    opacity: 1;
    transform: scale(1.05);
    margin: 15px 0;
    height: auto;
  }
  & .story-img {
    height: auto;
  }
}
.slick-center.slick-slide {
  & .story-img {
    height: auto;
    width: 100% !important;
    & img {
      height: auto !important;
      width: 100% !important;
    }
  }
}

.circleAnimation {
  -webkit-animation: circle 40s infinite alternate ease-in-out;
  animation: circle 40s infinite alternate ease-in-out;
}

@-webkit-keyframes circle {
  from {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  to {
    -webkit-transform: translate(400px);
    transform: translate(400px);
  }
}

@keyframes circle {
  from {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
  to {
    -webkit-transform: translate(400px);
    transform: translate(400px);
  }
}

.circleAnimation2 {
  -webkit-animation: circle2 40s infinite alternate ease-in-out;
  animation: circle2 40s infinite alternate ease-in-out;
}

@-webkit-keyframes circle2 {
  from {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  to {
    -webkit-transform: translateX(-400px);
    transform: translateX(-400px);
  }
}

@keyframes circle2 {
  from {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
  }
  to {
    -webkit-transform: translateX(-400px);
    transform: translateX(-400px);
  }
}

.vectiorAnimation {
  -webkit-animation: vector 8s infinite alternate ease-in-out;
  animation: vector 8s infinite alternate ease-in-out;
}

@-webkit-keyframes vector {
  from {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  to {
    -webkit-transform: translateX(20deg);
    transform: translateX(20deg);
  }
}

@keyframes vector {
  from {
    -webkit-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  to {
    -webkit-transform: translateX(20deg);
    transform: translateX(20deg);
  }
}

.vectiorAnimation2 {
  -webkit-animation: vector2 8s infinite alternate ease-in-out;
  animation: vector2 8s infinite alternate ease-in-out;
}

@-webkit-keyframes vector2 {
  from {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }
  to {
    -webkit-transform: translateX(0deg);
    transform: translateX(0deg);
  }
}

@keyframes vector2 {
  from {
    -webkit-transform: rotate(40deg);
    transform: rotate(40deg);
  }
  to {
    -webkit-transform: translateX(0deg);
    transform: translateX(0deg);
  }
}
.shadow-card {
  box-shadow: 0 10px 40px -8px rgba(0, 0, 0, 0.1);
}
//   .home-container{
// 	  background: url('../public/home-bg.png') no-repeat;
// 	  background-size:cover;
//   }
.shadow-input {
  box-shadow: 0 0 39px -8px rgba(0, 0, 0, 0.1);
}
.custom-x-scroll::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.custom-x-scroll::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.custom-track::-webkit-scrollbar-track {
  background-color: darkgrey;
  border-radius: 10px;
}
.bits-icon {
  & span {
    vertical-align: middle;
  }
}

.category-img {
  & img,
  span {
    width: 120px !important;
    height: 120px !important;
    display: block !important;
  }
}
.video-bg {
  & .full-img {
    & span {
      display: block !important;
      height: 100% !important;
    }
    & img {
      width: 100% !important;
      height: auto !important;
    }
  }
}
.bits-container {
  & .video-bg {
    & .full-img {
      & img {
        height: 70vh !important;
        object-fit: cover;
      }
    }
  }
}

.course-container {
  & .slick-prev {
    left: 10px;
  }
  & .slick-next {
    right: 30px;
  }
}
/* Tablet (xs)  */
@media only screen and (min-width: 280px) {
  .innerDiv {
    padding-left: 10px;
    padding-right: 10px;
  }
  .slider-section {
    & .card img {
      width: 120px !important;
      height: 120px !important;
    }
  }
  header {
    & .innerDiv {
      padding-top: 2px;
      padding-bottom: 2px;
    }
  }
  .progress-circle {
    width: 130px !important;
    height: 130px !important;
    box-sizing: border-box;
  }
  .mobileLogo {
    max-width: 110px;
  }
}

/* Tablet (sm)  */
@media only screen and (min-width: 640px) {
  .large-dropdown .active a {
    background: #f9c933;
  }
  .business-slider {
    padding: 80px 50px;
  }
  .innerDiv {
    padding-left: 60px;
    padding-right: 60px;
  }
  header {
    & .innerDiv {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .footerLink {
    display: block !important;
  }
  .subjectTab {
    display: flex !important;
  }
  .mobileLogo {
    max-width: 130px;
  }

  .slick-slide > div {
    opacity: 0.5;
  }
  .closeMobileBtn {
    display: none !important;
  }
  .videoPlayImg {
    // height: calc(100vh - 52px);
    & video {
      // height: calc(100vh - 52px);
    }
    & .full-img {
      // height: calc(100vh - 52px);
    }
  }
}

/* Tablet (md)  */
@media only screen and (min-width: 768px) {
  .innerDiv {
    padding-left: 60px;
    padding-right: 60px;
  }
  header {
    & .innerDiv {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
  .progress-circle {
    width: 170px !important;
    height: 170px !important;
    box-sizing: border-box;
  }
  .search-img {
    & img,
    span {
      width: 200px !important;
      height: 200px !important;
      display: block !important;
    }
  }
  .category-img {
    & img,
    span {
      width: 200px !important;
      height: 200px !important;
      display: block !important;
    }
  }
  .course-wrapper{
    &
    .swiper-pagination-bullets.swiper-pagination-horizontal{
      display: none;
    }
  }
}

/* Desktop (lg) */
@media only screen and (min-width: 1024px) {
  .innerDiv {
    padding-left: 80px;
    padding-right: 80px;
  }
  .NavContainer {
    display: flex !important;
  }
  header {
    & .innerDiv {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .progress-circle {
    width: 160px !important;
    height: 160px !important;
    box-sizing: border-box;
  }
  .business-slider {
    padding: 100px 50px;
  }
}

/* Desktop (xl)  */
@media only screen and (min-width: 1280px) {
  .innerDiv {
    max-width: calc(1280px + 160px);
    margin: 0 auto;
    padding-left: 80px;
    padding-right: 80px;
  }
  header {
    & .innerDiv {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .progress-circle {
    width: 160px !important;
    height: 160px !important;
    box-sizing: border-box;
  }
  .business-slider {
    padding: 150px 50px;
  }
}

/* Desktop (2xl)  */
@media only screen and (min-width: 1536px) {
  .innerDiv {
    max-width: 1280px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  header {
    & .innerDiv {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

/* Max Width 2560px */
main,
header,
footer {
  max-width: 2560px;
  margin: 0 auto;
}
.recommanded-course .swiper-button-prev,
.recommanded-course .swiper-button-next {
  background: #202020;
  height: 30px !important;
  width: 30px !important;
  border-radius: 50%;
  z-index: 10 !important;
  display: none;
  &::after {
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #edb300;
  }
  @media only screen and (min-width: 640px) {
    display: flex;
  }
}
.slider-section .swiper-button-prev,
.slider-section .swiper-button-next {
  background: #000;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50%;
  z-index: 10 !important;
  &::after {
    font-size: 18px !important;
    font-weight: 700 !important;
    color: #fff9e4;
  }

  @media only screen and (min-width: 1024px) {
    display: none !important;
  }
}

.slider-section .swiper-button-prev {
  left: 20px !important;
  transform: translateX(-50%);
}

.slider-section .swiper-button-next {
  right: 20px !important;
  transform: translateX(50%);
}

// bottom header
@media (min-width: 786px) {
  .headroom {
    display: none;
  }
}
.headroom {
  top: auto !important;
  bottom: 0 !important;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
  border-top-width: 2px;
  border-color: rgb(226 232 240);
  z-index: 10;
  padding: 0.5rem 0;
}
.headroom--unfixed {
  position: relative;
  transform: translateY(0);
}
.headroom--scrolled {
  transition: transform 200ms ease-in-out;
}
.headroom--unpinned {
  position: fixed;
  transform: translateY(100%) !important;
}
.headroom--pinned {
  position: fixed;
  transform: translateY(0%);
}
@media (max-width: 640px) {
  .course-container {
    & .slick-list {
      height: 100vh !important;
    }
  }

  .slick-slide {
    & .story-img {
      height: 100vh !important;
      & img {
        height: 100vh !important;
      }
    }
  }
  .logo-bits {
    & span {
      display: block !important;
    }
    & img {
      width: 36px !important;
      height: 36px !important;
    }
  }
  .slick-next {
    right: 0 !important;
  }
  //  .home-container{
  // 	 background:none;
  //  }
  .subject-list .card-img {
    & img {
      width: 100% !important;
    }
    & span {
      display: block !important;
    }
  }
  .course-container {
    & .slick-arrow {
      display: none;
    }
  }
  .story-img {
    & span {
      display: block !important;
    }
    & img {
      height: calc(100vh - 54px) !important;
      object-fit: cover;
    }
  }
  .content {
    min-height: 150px;
    overflow-y: scroll;
  }
  .search-img {
    & img,
    span {
      width: 90px !important;
      height: 90px !important;
      display: block !important;
    }
  }
  .userProfile {
    & span {
      display: block !important;
    }
    & img {
      width: 50px !important;
      height: 50px !important;
    }
  }
  .fluencers-images {
    & img {
      width: 100px !important;
      height: 100px !important;
    }
  }
  .category-icon {
    & span {
      display: block !important;
    }
    & img {
      width: 22px !important;
      height: 22px !important;
    }
  }
  .profile-cover {
    & span {
      display: block !important;
    }
    & img {
      height: 150px !important;
      object-fit: cover;
    }
  }
}
.videoCover {
  &:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    position: absolute;
    inset: 0;
    content: "";
    z-index: 0;
  }
}
@media (max-width: 1024px) {
  .hidemobileMenu {
    display: none !important;
  }
  .showMobile {
    display: block !important;
  }
  .video-bg {
    & .full-img {
      & img {
        width: 100% !important;
        min-height: 500px !important;
        object-fit: cover;
        object-position: top;
      }
    }
  }
  .custom-x-scroll::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}
.videoPlayImg {
  & .full-img {
    & span {
      display: block !important;
    }
    & img {
      width: 100% !important;
      height: 100vh !important;
      object-fit: cover;
    }
  }
}
.spinner-loader {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  vertical-align: -0.125em;
  border: 0.25em dotted #000000;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-loader;
  animation: 0.75s linear infinite spinner-loader;
}
@-webkit-keyframes spinner-loader {
  to {
    transform: rotate(360deg);
  }
}
.bottom-nav {
  & span {
    display: block !important;
  }
}
.nonactive {
  & .showactive {
    display: none !important;
  }
  & .hidenonactive {
    display: block !important;
  }
}
.pageactive {
  & .showactive {
    display: block !important;
  }
  & .hidenonactive {
    display: none !important;
  }
}
.thread-player-container {
  & .swiper-horizontal > .swiper-scrollbar {
    bottom: auto;
    top: 3px;
    opacity: 1 !important;
  }
}
.desktop-icon {
  // border: 1px solid #f8c933;
  box-shadow: 0 8px 20px -8px rgb(0 0 0 / 10%);
  //box-shadow: 0 0px 10px 2px rgb(0 0 0 / 5%);
  transform: translateX(-50%);
  filter: blur(5%);
  opacity: 1;
}
.desktop-icon {
  & span {
    display: block !important;
    & img {
      width: 20px !important;
      height: 20px !important;
    }
  }
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0 !important;
}
.bg-gradiant {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), transparent);
}
.bits-topheader {
  animation-duration: 1s; /* same as transition duration */
  animation-timing-function: linear; /* kind of same as transition timing */
  animation-delay: 0ms; /* same as transition delay */
  animation-iteration-count: 1; /* set to 2 to make it run twice, or Infinite to run forever!*/
  animation-direction: normal; /* can be set to "alternate" to run animation, then run it backwards.*/
  animation-fill-mode: none; /* can be used to retain keyframe styling after animation, with "forwards" */
  animation-play-state: running; /* can be set dynamically to pause mid animation*/
  animation-name: onHoverAnimation;
  background-color: rgba(0, 0, 0, 0.5);
}
@keyframes onHoverAnimation {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: rgba(0, 0, 0, 0.5);
  }
}
.bits-nav-desktop,
.dropdown-bg {
  backdrop-filter: blur(28px);
  background: rgba(0, 0, 0, 0.6);
}
.bits-nav-mobile {
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(28px);
}
.teacher-slider {
  & .swiper-pagination-bullet {
    background: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    border-radius: 5px;
    width: 30px;
    background: #ffffff;
  }
}
.course-wrapper {
  & .swiper-pagination-bullet {
    background: #000000;
    opacity: 1;
  }
  .swiper-pagination-bullet-active {
    background: #f8c933;
  }
}
.bitscategory-bg {
  background: linear-gradient(0deg, rgba(64, 64, 64, 0.2), rgba(64, 64, 64, 0.2)), radial-gradient(30.79% 99.67% at 50% 50%, #1f1f1f 0%, rgba(45, 45, 45, 0) 100%);
}
.play-btn {
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(6px);
}
.bits-topheader {
  & .logo-bits {
    & span {
      display: block !important;
    }
    & img {
      width: 28px !important;
      height: 28px !important;
      -webkit-transition: all 4s ease-in-out;
      -moz-transition: all 4s ease-in-out;
      -o-transition: all 4s ease-in-out;
      transition: all 4s ease-in-out;
    }
  }
}

[type="file"] {
  background: unset;
  border-color: inherit;
  border-radius: 0;
  border-width: 0;
  font-size: unset;
  line-height: inherit;
  padding: 0;
}
[type="file"]:focus {
  outline: 1px auto -webkit-focus-ring-color;
}
input[type="file"]::-webkit-file-upload-button {
  -webkit-margin-start: -1rem;
  -webkit-margin-end: 1rem;
  background: #374151;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  margin-inline-end: 1rem;
  margin-inline-start: -1rem;
  padding: 0.625rem 1rem 0.625rem 2rem;
}
input[type="file"]::file-selector-button {
  -webkit-margin-start: -1rem;
  -webkit-margin-end: 1rem;
  background: #374151;
  border: 0;
  color: #fff;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  margin-inline-end: 1rem;
  margin-inline-start: -1rem;
  padding: 0.625rem 1rem 0.625rem 2rem;
}
input[type="file"]::-webkit-file-upload-button:hover {
  background: #4b5563;
}
input[type="file"]::file-selector-button:hover {
  background: #4b5563;
}
.dark input[type="file"]::-webkit-file-upload-button {
  background: #4b5563;
  color: #fff;
}
.dark input[type="file"]::file-selector-button {
  background: #4b5563;
  color: #fff;
}
.dark input[type="file"]::-webkit-file-upload-button:hover {
  background: #6b7280;
}
.dark input[type="file"]::file-selector-button:hover {
  background: #6b7280;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
.shadow-profile {
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.1);
}

// Loader
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #ffffff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: "";
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: rgba(0, 0, 0, 1);
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: rgba(0, 0, 0, 1);
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.dropdown-icon {
  & span {
    vertical-align: middle;
  }
}
.pageload-modal {
  box-shadow: 0px 19px 40px -8px rgba(0, 0, 0, 0.15);
}
.player-top {
  background: linear-gradient(359.67deg, rgba(0, 0, 0, 0) 0.27%, #000000 89.34%);
}
.videoPlayImg {
  // height: calc(100vh - 64px);
  // & video {
  //   height: calc(100vh - 64px);
  // }
  & .full-img {
    height: calc(100vh - 64px);
  }
}
.avatarcircle {
  & span {
    display: block !important;
  }
}
.player-overly {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
}

::-webkit-scrollbar {
  width: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #f9c933;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.home-topheader {
  & li a.active {
    border: none !important;
    margin-bottom: 0;
  }
}
.master-container {
  &:after {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.598958) 39.06%, #000000 100%);
  }
  & .backdrop-text {
    backdrop-filter: blur(9px);
  }
  & .backdrop-bg {
    backdrop-filter: blur(11px);
  }
}

.thread-selection {
  backdrop-filter: blur(12px);
}

.transform-effect {
  -webkit-transition: all 2s ease;
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
}
.swiper-autoheight,
.swiper-autoheight .swiper-slide {
  height: 100vh !important;
}
.swiper-scrollbar-drag,
.swiper-scrollbar {
  background: transparent !important;
}
.text-shadow {
  text-shadow: 0px 0px 2px rgb(0 0 0 / 70%);
}
.story-gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
}
.explore-btn {
  // animation: hide 5s linear;
  //   opacity: 0;
  //   width: 0;
  //   height: 0;
  & span {
    animation: hide 5s linear;
    opacity: 0;
    width: 0;
  }
}
/* The animation code */
@keyframes hide {
  0% {
    opacity: 0;
    width: 0;
  }
  25% {
    opacity: .75;
    width: auto;
  }
  50% {
    opacity: .50;
    width: auto;
  }
  75% {
    opacity: .25;
    width: auto;
  }
  100% {
    opacity: 0;
    width: 0;
  }
}
.modal-bg{
  backdrop-filter: blur(8px);
}
.css-1pahdxg-control{
  box-shadow:0 0 0 1px #f9c933 !important;
  &:hover{
    border-color:#f9c933 !important;
  }
}
@keyframes up-right {
  0% {
      transform: scale(1);
      opacity: .25
  }
  50% {
      transform: scale (1, 5);
      opacity: 1;
  }
  100% {
      transform: scale(1);
      opacity: .25;
  }
}
.live-animation{
  -webkit-animation: up-right 1s infinite;
  -moz-animation: up-right 1s infinite;
  -o-animation: up-right 1s infinite;
  animation: up-right 1s infinite;

}
.notlive{
  &
  span{
    display:inline-block !important;
    vertical-align:bottom;
    &
    img{
      width:16px !important;
      height:18px !important;
      display:inline-block !important;
      object-fit:contain;
    }
  }
}
.disable-card{
  background:#dedede;
  &
  img{
   filter: grayscale(100%);
  }
}
.criteria-list{
  &
  >
  span{
    margin-bottom:10px;
    display:block;
    position:relative;
    padding-left:20px;
    &:after{
      content:"\f00c";
      position:absolute;
      font-family: "Font Awesome 6 Pro";
    font-weight: 400;
    left:0;
    top:0;
    }
  }
  & > span:last-child{
    display: none;
  }
}
.scheduler-container{
  backdrop-filter: blur(70px) !important;
  // background: rgba(0, 0, 0, 0.6) !important;
  left: 50%;
  transform: translate(-50%, -50%);
  top:50%;

}
#pre-loader{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items:center;
  justify-content: center;
}

#pre-loader svg{
  width: 140px;
}

#pre-loader path{
  stroke-dasharray: 2030;
  stroke-dashoffset: 2030;
  animation: logo-animation 2s ease-in-out alternate infinite;
}

@keyframes logo-animation {
  to{
      stroke-dashoffset: 0px;
  }
}

.loader-img {
  width: 100%;
  height: 100%;
  background-image: linear-gradient(90deg, transparent, rgba(90, 90, 90, 1) 50%, transparent 100%),linear-gradient(rgba(56, 56, 56, 1) 100%, transparent 0);
  background-repeat: no-repeat;
  background-position: -185px 0, center 0, center 115px, center 142px;
  box-sizing: border-box;
  animation: animloader 1s linear infinite;
  display:inline-block;
}
.loader-bgColor{
  background:#383838;
}


@keyframes animloader {
  to {
    background-position: 185px 0, center 0, center 115px, center 142px;
  }
}
*::selection 
{
    background-color:transparent;
} 
*::-moz-selection
{
    background-color:transparent;
}
*
{        
    -webkit-user-select: none;
    -moz-user-select: -moz-none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}    

.text-change{
  &:after{
    -webkit-animation-duration: 10s;
    animation-duration: 10s;
    content: "Chemistry";
    -webkit-animation-name: head;
    animation-name: head;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}
  }
  @keyframes teach {
    0% {
        opacity: 0;
    }
  
    50% {
        opacity: 1;
        content: '"Physics"';
    }
  
    55% {
        opacity: 0;
    }
  
    70% {
        opacity: 1;
        content: '"Bio"';
    }
  
    75% {
        opacity: 0;
    }
  
    100% {
        opacity: 1;
        content: '"Maths"';
    }
  }
  
  @keyframes head {
    0% {
      opacity: 0;
  }

  50% {
      opacity: 1;
      content: '"Physics"';
  }

  55% {
      opacity: 0;
  }

  70% {
      opacity: 1;
      content: '"Bio"';
  }

  75% {
      opacity: 0;
  }

  100% {
      opacity: 1;
      content: '"Maths"';
  }
  }
  // .searchbar-bits:focus +span{
  //   display:none;
  // }
  .text-shadowdate{
    text-shadow: 0px 0px 10px #000000;
  }
 #bits-player{
   height:calc(100vh - 64px);
  &
  .swiper-autoheight{
    height:100% !important;
  }
  &
  .swiper-autoheight .swiper-slide{
    height:100% !important;
  }
  &
  .swiper-wrapper{
    height:100% !important;
  }
  }
  .img-block{
    &
    span{
      display:block !important;
    }
  }
  .video-cover-img{
    &
    span{
      display:block !important
    }
    &
    img{
      width:100% !important;
      height:100% !important;
      object-fit: cover;
    }
  }
  .videobg-overly{
    background:linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  }



  // Omar

  // .headerLoader{
  //   position: absolute;
  //   bottom: 0; top: 0; left: 0; right: 0;
  //   height: 100%;
  //   background-color: black;
  //   width: 100%;

  //   .movingBar{      
  //     height: 5px;
  //     width: 100%;

  //     position: relative;


  //     &::before{
  //       content: "";
  //       position: absolute;
  //       bottom: 0;
  //       background: #f9c933;
  //       width: 200px;
  //       transform: translateX(-50%);
  //       position: relative;
  //       animation: headerLoadingAnimation 1s infinite alternate cubic-bezier(0.77, 0, 0.175, 1);
  //     }
      
  //   }

  //   @keyframes headerLoadingAnimation{
  //     0%{
  //       transform: translate(0%);
  //     }
  //     100%{
  //       transform: translate(100%);
  //     }
  //   }
  // }


  .headerLoaderWrapper {
    // background: black;
    // box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
    // width: 100%;
    // position: relative;
    
    &-loader {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 100%;
    }

   
  }

  .c-slidingLoader {
    position: relative;
    height: 4px;
    width: 100%;
    overflow: hidden;
    
    &-inner {
      position: absolute;
      width: 100%;
      height: 100%;
      animation: loading 1s infinite alternate cubic-bezier(0.77, 0, 0.175, 1);
      
      &::before {
        content: '';
        display: block;
        background-color: #f9c933;
        position: relative;
        height: 100%;
        width: 30%;
        transform: translateX(-50%);
      }
    }
    
    @keyframes loading {
      0% {
        transform: translateX(0);
      }
      
      100% {
        transform: translateX(100%);
      }
    }
  }
.filter-icon{
  backdrop-filter: blur(22px);
  transform: translate(-50%, -50%);
}
.work-section:after{
  content:"";
  position:absolute;
  right:0;
  background:url('../images/circle-bg.png') no-repeat;
  height:100%;
  width:25%;
  top:0;
  background-size: cover;
}
.blur-60{
  backdrop-filter: blur(60px);
}
.offer{
  background:url('../images/Rectangle.png') no-repeat;
  background-size:100% 100%;
}
.modalCenter{
  transform: translate(-50%, -50%);
}
.border-dashad{
  border: 1px dashed #999999;
}
.button-wrapper{
  display: flex;;
  background-color: #f9c933;
  width: 30px;
  align-items: center;
  justify-content: start;
  overflow: hidden;
  border-radius: 50px;
  padding: 3px 7px;
  animation-delay: 2s;
  animation: button-animaton 5s ease-in-out;
}

.arrow-container{
  width: 55px;
  display: flex; align-items: center;
  margin-left: 4px;
}


.text-container{
  margin-left: 8px;
}

@keyframes button-animaton{
  0%{
          width: 25px;
  }

  10%{
          width: 94px;
  }
  90%{
      width: 94px;
  }

  100%{
          width: 25px;
  }
}
.shadow-btn{
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
}
.otpInput-container{
  & div{
    width:100%;
    justify-content: space-between;
    & .otp-input{
      & input{
        border-bottom: 2px solid #edb300;
        width: 100% !important;
        padding: 10px 5px;
      }
    }
  }
}
.float-animation{
  -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    &:hover{
      -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    }
}
.icon-animation{
  -webkit-transition: font-size .2s ease-in-out;
  -moz-transition: font-size .2s ease-in-out;
  -o-transition: font-size .2s ease-in-out;
  transition: font-size .2s ease-in-out;
  &:hover{
    font-size:20px
  }
}
.box-hover-effect{
  &:hover h4{
    color:#F9C933;
    -webkit-transition: all .2s ease;
    -moz-transition: all .2s ease;
    -o-transition: all .2s ease;
    -ms-transition: all .2s ease;
    transition: all .2s ease;
  }
}
body.dark-mode{
  & .text-dark{
    color: #000000 !important;
  }
  & .slider-section, .chooseusSection, .dark-mode, main {
    background-color: #000000 !important;
    transition: all .5s ease-in-out;
    & h1{
      color: #ffffff !important;
    }
  }
  .bg-light-primary{
     & h2{
      color: #ffffff !important;
     }
  }
  .chooseusSection{
    & h2, h3{
      color: #ffffff !important;
    }
    & h4{
      color: #F9C933 !important;
    }
    & .bg-content{
      background-color: #000000 !important;
    }
    & .numberText{
      color: #4f4737 !important;
    }
  }
  & p{
    color: #ffffff !important;
  }
  .slider-section {
    & h2{
      color: #ffffff !important;
    }
  }
  .download {
    & h2, p{
      color: #000000 !important;
    }
  }
}


#lightBulbButton{
  width: 50px;
  background: none;
  border: none;
}

.light-bulb{
  .bulb-bottom-bar{
      fill: white;
  }
  .bulb-outline,.bulb-bottom-outline{
      fill: white;
  }
  .bulb-inner,.bulb-outer-bar{
      fill: none;
  }
}


  .bulb-on{
      .bulb-outline,.bulb-bottom-outline{
          fill: #f9c933;
      }
      .bulb-inner,.bulb-outer-bar{
          fill: #f9c933;
      }
  }


  /***********************************************
Sun/Moon Checkbox styling
***********************************************/
.moon-sun {
  width: 60px;
  display: block;
  overflow: hidden;
  border: none;
  & label {
    margin: 0;
    padding: 0 0 6px;
    width: 100%;
    display: block;
    font-size: 14px;
    line-height: 18px;
    color: #555;
    font-weight: bold;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
  }
  & input {
    margin: 0;
    padding: 0 10px;
    display: block;
    border-radius: 0;
    width: 100%;
    height: 40px;
    font-size: 14px;
    line-height: 40px;
    font-weight: bold;
    color: #555;
    border-width: 1px;
    border-style: solid;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    outline: 0 none;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-appearance: none;
    transition: all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s;
    -moz-transition: all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s;
    -webkit-transition: all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s;
    -o-transition: all 0.2s cubic-bezier(0.33, 0, 0.2, 1) 0s;
    display: none;
  }
  & input + label {
    width: 100%;
    height: 25px;
    background-color: #fefcfc;
    border-radius: 32px;
    border: 1px solid #525252;
    overflow: hidden;
    position: relative;
    top: 0;
    left: 0;
    z-index: 9;
    cursor: pointer;
    transition: background 0.5s cubic-bezier(0.33, 0, 0.2, 1) 0s, border 0.5s cubic-bezier(0.33, 0, 0.2, 1) 0s;
  }
  & input + label span {
    width: 16px;
    height: 16px;
    background-color: #f9c933;
    border-radius: 20px;
    display: block;
    position: absolute;
    top: 3px;
    left: 6px;
    transition: left 0.5s cubic-bezier(0.33, 0, 0.2, 1) 0s, background 0.5s cubic-bezier(0.33, 0, 0.2, 1) 0s;
  }
}

.moon-sun input:checked + label {
  background-color: #000000;
  border-color: #ffffff;
}
.moon-sun input:checked + label span {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border-radius: 20px;
  display: block;
  left: calc(50% + 2px);
  top: 0;
}

.moon-sun input + label span:before {
  content: "";
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #000000;
  position: absolute;
  top: -10px;
  left: -40px;
  transition: background 0.5s cubic-bezier(0.33, 0, 0.2, 1) 0s, left 0.5s cubic-bezier(0.33, 0, 0.2, 1) 0s;
}
.moon-sun input:checked + label span:before {
  background-color: #000000;
  left: -15px;
}

.moon-sun input + label .cloud {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20width%3D%2220.1px%22%20height%3D%2215.1px%22%20viewBox%3D%220%200%2020.1%2015.1%22%20style%3D%22enable-background%3Anew%200%200%2020.1%2015.1%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C%2Fstyle%3E%0A%3Cpath%20class%3D%22st0%22%20d%3D%22M17.1%2C8.8c0.3-0.8%2C0.5-1.6%2C0.5-2.5c0-3.5-2.8-6.3-6.3-6.3S5%2C2.8%2C5%2C6.3c0%2C0%2C0%2C0%2C0%2C0c-0.2%2C0-0.4%2C0-0.6%2C0%0A%09C2%2C6.3%2C0%2C8.3%2C0%2C10.7s2%2C4.4%2C4.4%2C4.4H17c1.7%2C0%2C3.1-1.4%2C3.1-3.1C20.1%2C10.2%2C18.8%2C8.9%2C17.1%2C8.8z%22%2F%3E%0A%3C%2Fsvg%3E);
  background-repeat: no-repeat;
  position: absolute;
  top: 5px;
  left: 15px;
  overflow: hidden;
  transition: left 0.5s cubic-bezier(0.33, 0, 0.2, 1) 0s;
}
.moon-sun input:checked + label .cloud {
  width: 20px;
  left: 60px;
}

.moon-sun input + label .stars {
  content: "";
  display: block;
  height: 25px;
  width: 60px;
  background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%20x%3D%220px%22%20y%3D%220px%22%0A%09%20width%3D%22110px%22%20height%3D%2240px%22%20viewBox%3D%220%200%20110%2040%22%20style%3D%22enable-background%3Anew%200%200%20110%2040%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0A%3Cstyle%20type%3D%22text%2Fcss%22%3E%0A%09.st0%7Bfill%3A%23FFFFFF%3B%7D%0A%3C%2Fstyle%3E%0A%3Ccircle%20class%3D%22st0%22%20cx%3D%2220.4%22%20cy%3D%227.5%22%20r%3D%222.5%22%2F%3E%0A%3Ccircle%20class%3D%22st0%22%20cx%3D%2219.9%22%20cy%3D%2223%22%20r%3D%221%22%2F%3E%0A%3Ccircle%20class%3D%22st0%22%20cx%3D%2241.4%22%20cy%3D%2218.5%22%20r%3D%221.5%22%2F%3E%0A%3Ccircle%20class%3D%22st0%22%20cx%3D%2235.4%22%20cy%3D%2229.5%22%20r%3D%221.5%22%2F%3E%0A%3Ccircle%20class%3D%22st0%22%20cx%3D%2255.9%22%20cy%3D%228%22%20r%3D%222%22%2F%3E%0A%3Ccircle%20class%3D%22st0%22%20cx%3D%227.4%22%20cy%3D%2218.5%22%20r%3D%220.5%22%2F%3E%0A%3Ccircle%20class%3D%22st0%22%20cx%3D%225.4%22%20cy%3D%226.5%22%20r%3D%220.5%22%2F%3E%0A%3C%2Fsvg%3E);
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: -70px;
  transition: left 0.5s cubic-bezier(0.33, 0, 0.2, 1) 0s;
}
.moon-sun input:checked + label .stars {
  left: 2px;
  transition: left 0.8s cubic-bezier(0.33, 0, 0.2, 1) 0s;
}
.coin-icon {
  & span {
    vertical-align: middle;
  }
}

.blur-homeTab {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 24.47%, #FFFFFF 100%);
  height: 48px;
  width: 106px;
  position: absolute;
  z-index: 100;
  top: 0px;
  right: 0px;
}
.bg-image{
  background:
  linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.40) 26.56%, #000 100%), url('../images/subscribe-bg.jpg'), lightgray 50% / cover no-repeat;  
}
.card-image{
  & img{
    display: block !important;
  }
  & span{
    display: block !important;
  }
}
#main-appbar{
  margin-top: 54px;
}